.panelContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:20px;
}

@media screen and (min-width: 1000px) {
    .panelContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: -10px;
    }
}