
.heroSection {
  background-color: transparent;
  color: #fff;
  padding: 30px 0 40px 0;
  text-align: center;
}
.heroSection p {
  text-align: center;
}

.heroContainer {
  margin: 0 auto;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.heroSectionMini {
  background-color: transparent;
  color: #fff;
  padding: 20px 0 40px 0;
  text-align: center;
}

.heroContent {
  max-width: 1000px;
  margin: 0 auto;
}

.heroTitle {
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 0;
}

.heroSubtitle {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 1.25rem;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.heroBody {
  color: hsla(0, 0%, 100%, 0.8);
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: center;
  padding: 0 10px;
}

.googleBadge {
  height: 66px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.heroImage {
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 3px 4px 0 hsla(196, 100%, 10%, 0.4);
}
.heroImageLeft {
  height: 250px;
  border-radius: 10px;
  margin-right: -80px;
  box-shadow: 0 3px 4px 0 hsla(196, 100%, 10%, 0.4);
}
.heroImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
@media screen and (min-width: 1000px) {
  .heroSection {
    text-align: left;
    padding-top: 0;
  }
  .heroSection p {
    text-align: justify;
  }
  .heroContainer {
    display: flex;
    flex-direction: row-reverse;
    max-width: 1000px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .heroImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 40px;
  }

  .heroTitle {
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .heroSubtitle {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    font-size: 1.25rem;
    margin-bottom: 20px;
    color: #fff;
  }
  .heroBody {
    color: hsla(0, 0%, 100%, 0.9);
    padding: 0 50px 0 0;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: justify;
  }
  .buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /*align-items: space-between;*/
    margin-top: 40px;
  }
}
