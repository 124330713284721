.button {
    font-family: arboria, sans-serif;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.1em;
    border-radius: 4px;
    transition: all 0.3s ease;
    box-shadow: 0 3px 4px 0 hsla(196, 100%, 10%, 0.1);
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
}

.buttonPrimary {
    background: #41cbb9 linear-gradient(180deg, #41cbb9 0%, #009db6 100%);
}
.buttonAccented {
    background: #cc6b41 linear-gradient(180deg, #ee8733 0%, #f3552d 100%);
}

a.buttonPrimary:hover {
    background: linear-gradient(0deg, #41cbb9 0%, #009db6 100%);
    transition: all 0.5s;
    color: #fff;
}
a.buttonAccented:hover {
    background: linear-gradient(0deg, #ee8733 0%, #f3552d 100%);
    transition: all 0.5s;
    color: #fff;
}

a.buttonPrimary:link,
a.buttonPrimary:visited,
a.buttonPrimary:active,
a.buttonAccented:link,
a.buttonAccented:visited,
a.buttonAccented:active{
    color: hsla(20, 100%, 100%, 0.85);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    font-weight: 600;
}

.buttonIcon {
    width: 1rem;
    margin-right: 10px;
    vertical-align: middle;
}
