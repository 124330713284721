@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
a,
a:link,
a:visited,
a:active {
  color: #49f1db;
  text-decoration: none;
  font-weight: 500;
}
a:hover {
  color: #fff;
  transition: all 0.3s;
}
