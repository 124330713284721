.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: hsla(196, 42%, 53%, 0.3);
    border-radius: 6px;
    box-shadow: 0 8px 8px 0 hsla(196, 100%, 10%, 0.2);
    width: 100%;
    margin: 20px 0;
}

.formGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
    margin-bottom: 20px;
}

.formGroup label {
    font-family: arboria, sans-serif;
    font-weight: 500;
    margin-bottom: 0.3rem;
}

.formGroup input, .formGroup textarea {
    letter-spacing: 0.05rem;
    font-family: arboria, sans-serif;
    padding: 12px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    width: 100%;
    background: hsla(196, 100%, 90%, 0.6);
    color: hsla(196, 100%, 10%, 0.8);
    resize: vertical;
}

.formGroup .selectContainer {
    border-radius: 4px;
    border: none;
    width: 100%;
    background: hsla(196, 100%, 90%, 0.6);
    color: hsla(196, 100%, 10%, 0.8);
    resize: vertical;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.formGroup .selectContainer select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Remove default arrow */
    padding: 12px;
    background: transparent;
    border: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
}

.formGroup select + i {
    color: #000;
    margin: 0 20px;
    padding: 0;
}

.formGroup input:focus, .form-group textarea:focus, .form-group select:focus {
    background: hsla(196, 100%, 90%, 0.9);
    outline: none;
}

label {
    letter-spacing: 0.09rem;
}

.formButton {
    padding: 0.75em 0.5em 1em 0.75em;
    border-radius: 4px;
    margin: 5px 0 5px 5px;
    text-align: center;
    /* padding-right: -0.25em; */
    background: hsla(220, 30%, 86%, 0) linear-gradient(180deg, #41cbb9 0%, #009db6 100%);
    box-shadow: 0 3px 4px 0 hsla(196, 100%, 10%, 0.1);
    font-weight: 500;
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 0.18em;
    font-family: arboria, sans-serif;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.formButton {
    text-decoration: none;
    color: #fff;
}

a.formButton {
    color: #fff;
}

a.formButton:hover {
    background: linear-gradient(0deg, #41cbb9 0%, #009db6 100%);
    transition: all 0.5s;
    color: #fff;
}