.side-drawer {
  position: fixed;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  margin-right: auto;
  z-index: 200;
  background: linear-gradient(0deg, #003b7e, #006da5, #009db6);
  transition: transform 0.3s ease-out;
  border: 0;
  border-collapse: collapse;
}
.side-drawer-items {
  padding: 16px;
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: arboria, sans-serif;
  border: 0;
  border-collapse: collapse;
}
.side-drawer-item {
  background-color: hsla(220, 30%, 86%, 0);
  padding: 1em 0 1.25em 0;
  border-radius: 4px;
  margin: 5px;
  text-align: center;
  /* padding-right: -0.25em; */
  text-decoration: none;
  width: 100%;
  border: 0;
  border-collapse: collapse;
}

a.side-drawer-item {
  color: #fff;
}

a.side-drawer-item:hover,
#close-icon:hover {
  color: #49f1db;
}

.side-drawer-item-active {
  background-color: hsla(212, 48%, 24%, 0.2);
  transition: background-color 1s;
}

a.side-drawer-item-active {
  color: #49f1db;
}

.side-drawer-icon-close {
  transition: all 0.3s;
  color: #fff;
  padding: 1.25em 0 0 0;
  cursor: pointer;
}
#close-icon {
  transition: all 0.3s;
}

.side-drawer-closed {
  display: none;
}

.side-drawer-open {
  transform: translateX(0%);
}
.side-drawer-close {
  transform: translateX(-100%);
}
