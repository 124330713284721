.articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin: 0;
}
.article {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 14px 14px 14px;
  margin: 10px;
  background-color: hsla(196, 42%, 53%, 0.3);
  box-shadow: 0 8px 8px 0 hsla(196, 100%, 10%, 0.2);
  border-radius: 6px;
  flex: auto;
}

.article h2 {
  margin: 0;
}
.article p {
  margin-top: 0;
}
.articleSingle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 3px;
  flex: 1 1 0;
}
sup {
  color: #49f1db;
}

.btnBack {
  background-color: hsla(220, 30%, 20%, 0.2);
  padding: 0.75em 0.5em 0.7em 0.5em;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  /* margin: 5px 0; */
  text-align: center;
  /* padding-right: -0.25em; */
  /* background: linear-gradient(180deg, #41cbb9 0%, #009db6 100%); */
  /* background: linear-gradient(180deg, #ee8733 0%, #f3552d 100%); */
  font-weight: 600;
  letter-spacing: 0.1em;
  /* box-shadow: 0 3px 4px 0 hsla(196, 100%, 10%, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  .articles {
    margin: 10px -10px;
  }
}
