@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css");

ul {
    list-style-type: none;

}

.dropdownContainer {
    width: 100%;
    border-radius: 4px;
    border: none;
    background: hsla(196, 100%, 90%, 0.6);
    color: hsla(196, 100%, 10%, 0.8);
    resize: vertical;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    font-weight: 600;
    position: relative;
}
.options {
    position: absolute;
    background-color: #256A90;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 100;
    margin: 0 0 0 10px;
    padding: 0 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 100%;
}
.optionContainer {
    padding: 0;
    margin: 0;
    position: relative;
}
.option {
    padding: 10px;
    text-decoration: none;
    display: block;
    margin:0;
}
.optionContainer:first-child {
    margin-top: 10px;
}
.optionContainer:last-child {
    margin-bottom: 10px;
}


.dropdownContainer a,
.dropdownContainer a:link,
.dropdownContainer a:visited,
.dropdownContainer a:active{
    color: hsla(196, 100%, 10%, 0.8);
    text-decoration: none;
}

.optionContainer a,
.optionContainer a:link,
.optionContainer a:visited,
.optionContainer a:active
{
    color: #fff;
    text-decoration: none;
}
.optionContainer a:hover,
.dropdown a:hover {
    color: #89BBD2;
    transition: all 0.3s;
}