

.panelContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.productPanel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 14px;
    margin: 10px;
    background-color: hsla(196, 42%, 53%, 0.3);
    border-radius: 6px;
    box-shadow: 0 8px 8px 0 hsla(196, 100%, 10%, 0.2);
    width: 100%;
}
.productImageContainer {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    background-image: linear-gradient(
            to top,
            #1f202f,
            #3b3d5c
    );
    padding: 20px;
    border-radius: 5px;
}
.productImage {
    width: 40%;
    min-width: 200px;
}

.productActionsButtonsContainer {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}


@media screen and (min-width: 450px) {
    .productActionsButtonsContainer {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
    }
    .productActionButton {
        margin-bottom: 0;
        margin-right: 10px;
    }
    .productActionButton:last-child {
        margin: 0;
    }
}

@media screen and (min-width: 1000px) {
    .panelContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: -10px;
    }

    .productPanel {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 14px;
        margin-right: 20px;
        background-color: hsla(196, 42%, 53%, 0.3);
        border-radius: 6px;
        /* box-shadow: 0 8px 8px 0 hsla(196, 100%, 10%, 0.2); */
        /* width: 310px; */
        flex: 1 1 400px;
    }

    .productPanel h2 {
        margin-top: 0;
    }

    .productImageContainer {
        margin-right: 20px;
    }
}