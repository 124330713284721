.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: arboria, sans-serif;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding-right: -0.25em;
  min-width: 80px;
}
.menuItem {
  background-color: hsla(220, 30%, 86%, 0);
  padding: 0.75em 0.5em 1em 0.75em;
  border-radius: 4px;
  margin: 5px;
  text-align: center;
  /* padding-right: -0.25em; */
  text-decoration: none;
}

a.menuItem {
  color: #fff;
}

a.menuItem:hover {
  color: #49f1db;
}

.menuItem:last-child {
  margin-right: -10px;
}

.menuItemActive {
  background-color: hsla(212, 48%, 24%, 0.2);
  transition: background-color 1s;
}

a.menuItemActive {
  color: #49f1db;
}

.loginButton {
  padding: 0.75em 0.5em 1em 0.75em;
  border-radius: 4px;
  margin: 5px 0 5px 5px;
  text-align: center;
  /* padding-right: -0.25em; */
  background: hsla(220, 30%, 86%, 0) linear-gradient(180deg, #41cbb9 0%, #009db6 100%);
  font-weight: 600;
  letter-spacing: 0.1em;
  box-shadow: 0 3px 4px 0 hsla(196, 100%, 10%, 0.1);
}

.loginButton {
  text-decoration: none;
  color: #fff;
}

a.loginButton {
  color: #fff;
}

a.loginButton:hover {
  background: linear-gradient(0deg, #41cbb9 0%, #009db6 100%);
  transition: all 0.5s;
  color: #fff;
}
