.panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 14px;
    margin: 10px;
    background-color: hsla(196, 42%, 53%, 0.3);
    border-radius: 6px;
    box-shadow: 0 8px 8px 0 hsla(196, 100%, 10%, 0.2);
    width: 100%;
}

.panel h3 {
    font-family: arboria, sans-serif;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.3rem;
}
@media screen and (min-width: 1000px) {
    .panel {
        flex: 1 1 400px;
    }
}