.container {
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 8px 0;
}

.siteHeader {
  /* background-color: hsl(220, 30%, 86%); */
  /* background: linear-gradient(
    90deg,
    rgb(42, 148, 143) 0%,
    rgb(29, 113, 101) 35%,
    rgb(9, 48, 45) 100%
  ); */
  text-align: center;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  position: relative;
  /* box-shadow: 0 4px rgba(0, 0, 0, 0.03); */
}

.logo {
  height: 30px;
  -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
}
.menuIcon {
  padding: 0.75em 0.5em 1em 0.75em;
  cursor: pointer;
  min-width: 80px;
  text-align: left;
}

.menuIconBars {
  color: #fff;
  margin-left: 10px;
  transition: all 0.3s;
}
.menuIcon:hover .menuIconBars {
  color: #49f1db;
}
