.appContainer {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    color: #174b6c;
    border-collapse: collapse;
    padding: 0;
    z-index: -2;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-image: linear-gradient(
            to left top,
            #2d0144,
            #003b7e,
            #006da5,
            #009db6,
            #41cbb9
    );
}

.bodyContainer {
    background-color: hsla(212, 48%, 24%, 0.5);

    /* background-color: #fff; */
    color: #fff;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0 8px 8px 0 hsla(196, 100%, 10%, 0.2);
    flex: 1;
    width: 100%;
    padding: 0 20px;
}

.bodyContent {
    /* background-color: orange; */
    margin: 0 auto;
    width: 1000px;
    padding: 0;
}

.bodyLeft {
    padding: 0;
    width: 100%;
    vertical-align: top;
}
.bodyRight {
    padding: 0;
    width: 100%;
    vertical-align: top;
}

.tagline {
  /* text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); */
  font-size: 1.1rem;
  /*margin-bottom: 20px;*/
  color: #fff;
}
 .tagline p {
     padding: 10px 0;
     font-size: 1.1rem;
     color: #fff;
     font-weight: 600;
     text-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
     text-align: justify;
 }

@media screen and (min-width: 1000px) {

    .bodySplit {
        /*align-items:flex-start;*/
        display:flex;
        flex-direction:row;
        justify-content:space-between
    }
    .bodyLeft {
        padding-right: 10px;
        width: 75%;
        vertical-align: top;
    }
    .bodyRight {
        padding-left: 10px;
        width: 25%;
        vertical-align: top;
    }
}