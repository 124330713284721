@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
  overscroll-behavior: none;
  scroll-behavior: smooth;
}

html {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  min-width: 375px;
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
  overflow-y: scroll;
}
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #174b6c;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #009db6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h3,
h4,
h5,
h6,
label {
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

p,
li {
  font-size: 1rem;
  line-height: 1.4rem;
  margin-bottom: 10px;
  color: hsla(0, 0%, 100%, 0.9);
  text-align: justify;
}
strong {
  color: #fff;
}
